<template>
  <div class="subscription-plans-page loading-state" v-if="!featureSwitchesLoadedState">
    <div class="limited_content">
      <mcr-loading-indicator :loading="true"></mcr-loading-indicator>
    </div>
  </div>
  <records-paywall-page
    v-else-if="featureSwitchesState.RECORDS_PAYWALL_NEW && redirectedFromRecord"
    :plans-loading="plansLoading"
    :stripe-loading="stripeLoading"
    :can-subscribe="!currentSubscription"
    :is-trial-eligible="isTrialEligibleState"
    :standard-trial-days="standardTrialDays"
    :plan="standardYearlyPlanState"
    @start-subscription="startSubscription"
  ></records-paywall-page>
  <div class="subscription-plans-page" v-else>
    <xmas-banner-subscription-plans></xmas-banner-subscription-plans>
    <cny-banner-subscription-plans></cny-banner-subscription-plans>
    <div class="limited_content">
      <div class="heading">
        <h1 class="title">{{ mainTitle }}</h1>
        <div class="sub-title heading-6">{{ subTitle }}</div>
      </div>

      <div class="is-switch">
        <div class="tab" :class="{'is-active': isPricingMonthly}" @click="setPricingMonthly">Monthly</div>
        <div
          class="tab"
          :class="{'is-active': isPricingYearly}"
          @click="setPricingYearly"
          v-if="!userIsSubscribedState && featureSwitchesState.MIDAUTUMN_SALE"
        >
          Yearly
        </div>
        <div class="tab" :class="{'is-active': isPricingYearly}" @click="setPricingYearly" v-else>Yearly -53%</div>
      </div>

      <stand-out-block-list>
        <stand-out-block class="free-plan" v-if="showFreePlanCard">
          <div class="plan-content">
            <div class="plan-name">{{ basicPlanNameWithEmoji }}</div>
            <div class="price">$0</div>
            <div class="price-explanation">FREE forever</div>
            <template v-if="!currentSubscription && !plansLoading">
              <div v-if="userIsLoggedInState" class="current-plan text-sm">Current Plan</div>
              <mcr-button-router-link v-else class="button ghost" :to="registerRoute"
                >Sign Up for FREE</mcr-button-router-link
              >
            </template>
            <div
              v-if="standardDisclaimer && isTrialEligibleState && !plansLoading"
              class="disclaimer invisible no-select"
              v-html="standardDisclaimer"
            ></div>
            <hr />
            <div class="plan-description">
              <div class="item checked">Family Tree Builder</div>
              <div class="item checked">Photos & Files Management</div>
              <div class="item checked">Search Chinese Surnames</div>
              <div class="item checked">Search Ancestral Villages</div>
              <div class="item checked">
                <mcr-wiki-glossary-link
                  :is-help-text="true"
                  naturalId="text-extraction"
                  text="Extract Text from Photos"
                />
              </div>
            </div>
          </div>
        </stand-out-block>
        <stand-out-block class="standard-plan">
          <div class="plan-content">
            <div class="plan-name">{{ standardPlanName }}</div>
            <div class="bold text-lg yearly-discount" v-if="yearly50PercentOff">🎁 50% off</div>
            <mcr-loading-indicator v-if="plansLoading" :loading="true"></mcr-loading-indicator>
            <template v-else>
              <div class="price">
                {{ standardCost.value }} <span class="period">/{{ standardCost.period }}</span>
              </div>
              <div class="bill-description" :class="{'invisible no-select': !standardCost.fullValue}">
                <span :class="{discounted: standardCost.fullValueDiscounted}">{{ standardCost.fullValue }}</span
                ><span v-if="standardCost.fullValueDiscounted"> {{ standardCost.fullValueDiscounted }}</span
                ><span> {{ standardCost.periodDescription }}</span>
              </div>
            </template>
            <div v-if="plansLoading"></div>
            <div v-else-if="isCurrentSubscriptionSelected" class="current-plan text-sm">
              Current Plan
              <span v-if="currentSubscription && currentSubscription.cancel_at_period_end">(Canceled)</span>
            </div>
            <start-subscription-button
              v-else-if="canSubscribe"
              class="button"
              :will-switch-to-annual="willSwitchToAnnual"
              :plan="standardSelectedPlan"
              :loading="stripeLoading"
              @start-subscription="startSubscription"
            >
              {{ standardSubscribeLabel }}
            </start-subscription-button>
            <div v-if="showCancel" class="cancel-plan">
              <subscription-cancel-button :subscription="currentSubscription" />
            </div>
            <div v-if="standardDisclaimer && !plansLoading" class="disclaimer" v-html="standardDisclaimer"></div>
            <div v-if="isCurrentSubscriptionPaymentFailed" class="disclaimer">
              <router-link :to="{name: 'user-profile'}">Edit billing info</router-link>
            </div>
            <subscription-reactivate-button v-if="canReactivateSubscription" :subscription="currentSubscription">
              <template v-slot:element="{loading}">
                <mcr-button :loading="loading">Reactivate Membership</mcr-button>
              </template>
            </subscription-reactivate-button>
            <hr />
            <div class="plan-description">
              <div class="item text-lg bold-medium" v-if="showFreePlanCard">
                Everything in {{ basicPlanName }} plus...
              </div>
              <div class="item checked">
                <mcr-wiki-glossary-link :is-help-text="true" naturalId="hints-matching-system" text="RecordFinder™" />
              </div>
              <div class="item checked">Search Overseas Collections</div>
              <div class="item checked">Search China Collections</div>
              <div class="item checked">
                <mcr-wiki-glossary-link :is-help-text="true" naturalId="subscriber-ocr" text="Enhanced OCR" />
              </div>
              <div class="item highlight" v-if="isPricingYearly && !userIsSubscribedState && freeYearPromo">
                <div class="icon">🎁</div>
                <span>Second Year Free!</span>
              </div>
            </div>
          </div>
        </stand-out-block>
      </stand-out-block-list>

      <div v-if="!showFreePlanCard" class="basic-plan-overview">
        <div class="heading-6">Plus everything from {{ basicPlanName }} (free) plan:</div>
        <div class="benefits-list text-lg">
          <div class="column">
            <div>✓ Family Tree Builder</div>
            <div>✓ Search Chinese Surnames</div>
            <div>✓ Search Ancestral Villages</div>
          </div>
          <div class="column">
            <div>✓ Photos & Files Management</div>
            <div>
              <mcr-wiki-glossary-link
                :is-help-text="true"
                naturalId="text-extraction"
                text="✓ Extract Text from Photos"
              />
            </div>
          </div>
        </div>
        <div class="see-all-plans text-lg"><a @click="seeAllPlansClick">See all plans</a></div>
      </div>

      <div class="login-link-cta" v-if="!userIsLoggedInState">
        Already a member? <router-link :to="loginRoute">Login.</router-link>
      </div>

      <div class="sub-information">
        <hr />
        <div class="heading-4">Trusted by heritage keepers worldwide.</div>
        <logo-wall></logo-wall>
        <hr />
      </div>

      <div class="readable_content video-block">
        <div class="title heading-4">How My China Roots Works</div>
        <div @click="onVideoClick">
          <lazy-youtube-video :src="howMcrWorksVideo"></lazy-youtube-video>
        </div>
      </div>

      <div class="information">
        <div class="readable_content welcome">
          <div class="heading-3 heading-4-mobile">
            The fastest-growing database of Chinese ancestor records, now at your fingertips.
          </div>
          <p class="text-lg text-lg-mobile">
            Gain unprecedented access to over 5+ million
            <mcr-wiki-glossary-link :is-help-text="true" naturalId="whySearchZupu" text="family tree books" /> (zupu
            族谱 or jiapu 家谱), immigration case files, censuses, tombstones, directories, and more!
          </p>
          <img
            :data-src="$getAsset('/assets/plans/records-gradient.jpg')"
            class="lazyload in-layer"
            width="800"
            height="445"
          />
        </div>

        <div class="image_panel">
          <div class="image_panel_text">
            <div class="heading-4">🌿 Retrace your roots, no Chinese fluency required</div>
            <div class="text-block text-lg text-lg-mobile">
              <p>
                Rediscover your family’s original Chinese surname and hometowns, using our bilingual family tree and OCR
                transcription features.
              </p>
            </div>
          </div>
          <div class="image_panel_image">
            <img
              :data-src="$getAsset('/assets/video/my-china-roots_chinese-surname-recognition.gif')"
              class="lazyload gif"
              width="550"
              height="330"
            />
          </div>
        </div>

        <div class="image_panel reverse_panel">
          <div class="image_panel_text">
            <div class="heading-4">🕵 Make quick breakthroughs with RecordFinder™</div>
            <div class="text-block text-lg text-lg-mobile">
              <p>
                Simply add relatives to your tree, and we'll automatically find matching records for you in the My China
                Roots database.
              </p>
            </div>
          </div>
          <div class="image_panel_image">
            <img
              :data-src="$getAsset('/assets/video/my-china-roots_hints-review.gif')"
              class="lazyload gif"
              width="550"
              height="340"
            />
          </div>
        </div>

        <div class="image_panel">
          <div class="image_panel_text">
            <div class="heading-4">🔒 Respect for your data privacy and security, guaranteed</div>
            <div class="text-block text-lg text-lg-mobile">
              <p>
                Your peace of mind is our top priority. As fellow family historians, we promise to safeguard your
                information with the utmost care and confidentiality. Your data – your family history – belongs to you.
                <mcr-wiki-glossary-link text="Learn more." natural-id="privacy-promise"></mcr-wiki-glossary-link>
              </p>
            </div>
          </div>
          <div class="image_panel_image">
            <img
              :data-src="$getAsset('/assets/plans/researcher-family-photo-1.jpg')"
              class="lazyload"
              width="550"
              height="400"
            />
          </div>
        </div>

        <div class="image_panel reverse_panel">
          <div class="image_panel_text">
            <div class="heading-4">🌳 Bilingual Family Trees</div>
            <div class="text-block text-lg text-lg-mobile">
              <p>
                Capture the dynamic identities of your Chinese ancestors with our bilingual Family Tree Builder. Keep
                track of multiple aliases and auto-detect the Chinese characters of their romanized names. Free for all
                users!
              </p>
            </div>
          </div>
          <div class="image_panel_image">
            <img
              :data-src="$getAsset('/assets/services/family-tree-software-cropped.png')"
              class="in-layer lazyload"
              width="550"
              height="345"
            />
          </div>
        </div>

        <div class="image_panel">
          <div class="image_panel_text">
            <div class="heading-4">💡 Context-Rich Records</div>
            <div class="text-block text-lg text-lg-mobile">
              <p>
                Can’t read Chinese? No problem. Every record is indexed in meticulous detail to illuminate the history
                behind each page. With the built-in Chinese transcription and translation features, it’s easier than
                ever to decode the hidden stories of your ancestors.
              </p>
            </div>
          </div>
          <div class="image_panel_image">
            <img :data-src="$getAsset('/assets/services/tree/records.jpg')" class="lazyload" width="550" height="355" />
          </div>
        </div>

        <div class="image_panel reverse_panel">
          <div class="image_panel_text">
            <div class="heading-4">🧩 Automatic Record Matching</div>
            <div class="text-block text-lg text-lg-mobile">
              <p>
                Save time and energy searching for records. With RecordFinder™, we automatically find the most relevant
                records for you. Just update your family tree and we’ll send matching records your way.
              </p>
            </div>
          </div>
          <div class="image_panel_image">
            <img
              :data-src="$getAsset('/assets/services/recordmatch_landing_hero.png')"
              class="in-layer lazyload"
              width="550"
              height="290"
            />
          </div>
        </div>

        <div class="image_panel">
          <div class="image_panel_text">
            <div class="heading-4">🙌🏼 Preserve the future of Chinese heritage</div>
            <div class="text-block text-lg text-lg-mobile">
              <p>
                We’re racing against time to preserve records in disappearing villages. Your membership directly
                sustains our digitization efforts, ensuring access to Overseas Chinese descendants around the world.
                More than just a database, join the My China Roots community in our global mission to build bridges of
                belonging for generations to come.
              </p>
            </div>
          </div>
          <div class="image_panel_image">
            <img :data-src="$getAsset('/assets/services/film/film1.jpg')" class="lazyload" width="550" height="370" />
          </div>
        </div>
      </div>
    </div>

    <div class="privileges">
      <div class="heading-3 heading-4-mobile">Early members enjoy exclusive access to pioneer privileges</div>
      <div class="list">
        <div class="item">
          <div class="emoji">🌿</div>
          <div class="item-title">Lifetime Savings</div>
          <p class="text-lg text-lg-mobile">
            Lock in your My China Roots membership now at a special early-bird rate, guaranteed for life!
          </p>
        </div>
        <div class="item">
          <div class="emoji">🌿</div>
          <div class="item-title">Sustainable Legacy</div>
          <p class="text-lg text-lg-mobile">
            We’re racing against time to preserve records in disappearing villages. Your membership directly sustains
            our digitization efforts, ensuring access for generations to come.
          </p>
        </div>
        <div class="item">
          <div class="emoji">🌿</div>
          <div class="item-title">Priority Feedback and Support</div>
          <p class="text-lg text-lg-mobile">
            Your voice matters. As an early member, you will have a direct say in the development of our genealogy
            platform and enjoy personalized tech support!
          </p>
        </div>
      </div>
    </div>

    <end-actions v-if="canSubscribe">
      <div class="heading-2" v-if="freeYearPromo && !userIsSubscribedState">
        Secure your extra year of FREE membership now!
      </div>
      <div class="heading-2" v-else-if="featureSwitchesState.MIDAUTUMN_SALE && !userIsSubscribedState">
        Enjoy 50% Off First Year!
      </div>
      <div class="heading-2" v-else>Secure your early membership now!</div>
      <div>Write the future of Chinese genealogy together, starting with your story.</div>
      <start-subscription-button
        class="button"
        :will-switch-to-annual="willSwitchToAnnual"
        :plan="standardSelectedPlan"
        :loading="stripeLoading"
        @start-subscription="startSubscription"
      >
        {{ standardSubscribeLabel }}
      </start-subscription-button>
    </end-actions>
  </div>
</template>

<script>
import EndActions from '@common/elements/buttons/endActions';
import McrButton from '@common/elements/buttons/mcrButton.vue';
import McrButtonRouterLink from '@common/elements/buttons/mcrButtonRouterLink';
import McrWikiGlossaryLink from '@common/elements/glossary/mcrWikiGlossaryLink';
import StandOutBlock from '@common/elements/layouts/StandOutBlock';
import AnalyticsAmplitudeHandler from '@common/utils/analytics/analytics.amplitude';
import {getRoutePageName} from '@common/utils/analytics/utils.analytics';
import {TAB_ID_INDEXED_RECORDS} from '@common/utils/consts.search';
import omit from 'lodash/omit';
import moment from 'moment';
import LazyYoutubeVideo from 'vue-lazy-youtube-video';
import {mapGetters} from 'vuex';

import StandOutBlockList from '@/base/elements/layouts/StandOutBlockList';

import SubscriptionCancelButton from '@/components/common/SubscriptionCancelButton';
import SubscriptionReactivateButton from '@/components/common/SubscriptionReactivateButton.vue';
import LogoWall from '@/components/common/images/LogoWall';
import CnyBannerSubscriptionPlans from '@/components/common/promotions/cnyBannerSubscriptionPlans';
import XmasBannerSubscriptionPlans from '@/components/common/promotions/xmasBannerSubscriptionPlans';
import {PRICING_YEARLY, PRICING_MONTHLY} from '@/components/common/subscriptions/utils';

import RecordsPaywallPage from '@/components/modules/subscriptionPlans/RecordsPaywallPage';
import StartSubscriptionButton from '@/components/modules/subscriptionPlans/StartSubscriptionButton';

export default {
  metaInfo: {
    title: 'My China Roots - Membership Subscriptions',
    titleTemplate: null,
    meta: [
      {vmid: 'og:title', property: 'og:title', content: 'My China Roots - Membership Subscriptions'},
      {
        vmid: 'og:description',
        property: 'og:description',
        content:
          'Become a My China Roots member and explore thousands of historical records online from China and abroad. Find out more and subscribe today.',
      },
      {
        vmid: 'description',
        name: 'description',
        content:
          'Become a My China Roots member and explore thousands of historical records online from China and abroad. Find out more and subscribe today.',
      },
      {
        vmid: 'robots',
        name: 'robots',
        content: 'noindex,nofollow',
      },
    ],
  },
  data() {
    let period = this.$route.query.billing_period;
    return {
      pricing: [PRICING_YEARLY, PRICING_MONTHLY].includes(period) ? period : PRICING_YEARLY,
      stripeLoading: false,
      previousRoute: null,
      howMcrWorksVideo: 'https://www.youtube.com/embed/l4ZFzd6YJFo',
      registerRoute: {name: 'register', query: {redirect: this.$router.resolve({name: 'familytree-details-my'}).href}},
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.name) {
        vm.previousRoute = {name: from.name, params: {...from.params}, query: {...from.query}};
      }
    });
  },
  created() {
    if (this.$store.getters.userIsSubscribedState && this.$route.query.redirect) {
      this.$router.replace(this.$route.query.redirect);
      return;
    }
    this.$stripe.init();
    this.$store.dispatch('fetchSubscriptionPlansAction');
    this.$store.dispatch('fetchSubscriptionsAction').then(res => {
      if (this.$route.query.autostart_plan && !this.currentSubscription) {
        this.startSubscription(this.$route.query.autostart_plan);
      }
    });
    if (
      this.$route.query.share_link_code &&
      (!this.subscriberLinkState || this.subscriberLinkState.code !== this.$route.query.share_link_code)
    ) {
      this.$store.commit('setSubscriberLinkState', null);
      this.$store.dispatch('resolveSubscriberLinkAction', {code: this.$route.query.share_link_code});
    }
  },
  computed: {
    ...mapGetters([
      'subscriptionPlansState',
      'standardYearlyPlanState',
      'isTrialEligibleState',
      'subscriptionsState',
      'userIsLoggedInState',
      'userIsSubscribedState',
      'featureSwitchesState',
      'featureSwitchesLoadedState',
      'subscriberLinkState',
    ]),
    redirectedFromRecord() {
      return this.$route.query.hasOwnProperty('record_title');
    },
    mainTitle() {
      if (this.$route.query.share_link_code && this.subscriberLinkState && this.subscriberLinkState.paywall_message) {
        return this.subscriberLinkState.paywall_message;
      }
      if (this.redirectedFromRecord) {
        return 'Chinese genealogy made easy';
      }
      if (this.$route.query.hints) {
        return 'Become a member to grow your tree with RecordFinder™';
      }
      return 'Grow your tree. Discover your roots.';
    },
    subTitle() {
      return 'The simplest way to research and preserve your Chinese family history.';
    },
    isPricingMonthly() {
      return this.pricing === PRICING_MONTHLY;
    },
    isPricingYearly() {
      return this.pricing === PRICING_YEARLY;
    },
    basicPlanName() {
      const plan = this.subscriptionPlansState.find(plan => plan.is_basic_plan);
      return plan ? plan.display_text : '';
    },
    basicPlanNameWithEmoji() {
      return this.basicPlanName ? `🌳 ${this.basicPlanName}` : '';
    },
    standardPlanName() {
      return this.standardSelectedPlan ? `🔍 ${this.standardSelectedPlan.display_text}` : '';
    },
    standardMonthlyPlan() {
      return this.subscriptionPlansState.find(
        plan => plan.billing_period === PRICING_MONTHLY && plan.name.includes('Standard')
      );
    },
    standardYearlyCost() {
      return this.standardYearlyPlanState && this.standardYearlyPlanState.cost;
    },
    standardSelectedPlan() {
      const mapping = {
        [PRICING_MONTHLY]: this.standardMonthlyPlan,
        [PRICING_YEARLY]: this.standardYearlyPlanState,
      };
      return mapping[this.pricing];
    },
    standardCost() {
      const monthlyCost = this.standardMonthlyPlan && this.standardMonthlyPlan.cost;
      const yearPeriod = this.freeYearPromo ? 'billed yearly after 2 years' : 'billed yearly';
      let yearlyMonthlyCostFixed = this.yearlyMonthlyCostString
        ? this.yearlyMonthlyCostString.slice(0, this.yearlyMonthlyCostString.indexOf('.') + 3)
        : '';
      if (this.yearly50PercentOff) {
        yearlyMonthlyCostFixed = '3.75';
      }
      const fullValueDiscounted = this.yearly50PercentOff ? '44.99' : null;
      const mapping = {
        [PRICING_MONTHLY]: {
          value: `$${monthlyCost || ''}`,
          period: 'month',
          periodDescription: 'billed monthly',
        },
        [PRICING_YEARLY]: {
          value: `$${yearlyMonthlyCostFixed || ''}`,
          period: 'month',
          fullValue: `$${this.standardYearlyCost || ''}`,
          fullValueDiscounted: fullValueDiscounted ? `$${fullValueDiscounted}` : '',
          periodDescription: yearPeriod,
        },
      };
      return mapping[this.pricing];
    },
    yearlyMonthlyCostString() {
      if (!this.standardYearlyCost) {
        return '';
      }
      if (this.freeYearPromo) {
        const price = this.standardYearlyCost / 24;
        const priceTwoDecimal = Math.round((price + Number.EPSILON) * 100) / 100;
        return priceTwoDecimal.toString();
      }
      return (this.standardYearlyCost / 12).toString();
    },
    standardSubscribeLabel() {
      if (this.isTrialEligibleState) {
        return `Start Free ${this.standardTrialDays} Day Trial`;
      }
      if (this.willSwitchToAnnual) {
        return 'Switch to Annual';
      }
      return 'Start Membership';
    },
    willSwitchToAnnual() {
      return (
        this.isPricingYearly &&
        this.currentSubscription &&
        this.currentSubscription.plan.billing_period === PRICING_MONTHLY
      );
    },
    canSubscribe() {
      if (this.currentSubscription) {
        return this.currentSubscription.plan.billing_period === PRICING_MONTHLY;
      }
      return true;
    },
    canReactivateSubscription() {
      return (
        this.isCurrentSubscriptionSelected &&
        this.currentSubscription &&
        this.currentSubscription.status === 'active' &&
        this.currentSubscription.cancel_at_period_end
      );
    },
    currentSubscription() {
      for (let sub of this.subscriptionsState) {
        if (sub.status === 'active' || sub.status === 'payment_failed') {
          return sub;
        }
      }
    },
    isCurrentSubscriptionSelected() {
      if (!this.currentSubscription) {
        return false;
      }
      if (this.currentSubscription.plan.billing_period === 'free' && !this.currentSubscription.plan.is_basic_plan) {
        return true;
      }
      return this.pricing === this.currentSubscription.plan.billing_period;
    },
    isCurrentSubscriptionPaymentFailed() {
      return this.currentSubscription && this.currentSubscription.status === 'payment_failed';
    },
    standardTrialDays() {
      if (this.standardSelectedPlan) {
        return this.standardSelectedPlan.trial_days || 0;
      }
      return 0;
    },
    standardTrialEndDate() {
      let result = new Date();
      result.setDate(result.getDate() + this.standardTrialDays);
      return moment(result).format('MMM DD, YYYY');
    },
    subscriptionExpireOnDisplay() {
      return moment(this.currentSubscription.expire_on).format('MMM DD, YYYY');
    },
    standardDisclaimer() {
      if (this.isTrialEligibleState) {
        return `You won’t be charged until ${this.standardTrialEndDate}.<br>Cancel anytime.`;
      }
      if (this.currentSubscription && this.currentSubscription.cancel_at_period_end) {
        return `You will be downgraded on ${this.subscriptionExpireOnDisplay}. `;
      }
      if (this.isCurrentSubscriptionPaymentFailed) {
        return 'Your subscription is paused. ';
      }
    },
    plansLoading() {
      return this.$store.getters.subscriptionPlansLoadingState || this.$store.getters.subscriptionsLoadingState;
    },
    loginRoute() {
      return {name: 'login', query: {redirect: this.$route.query.redirect}};
    },
    successUrl() {
      if (this.$route.query.share_link_code && this.subscriberLinkState) {
        return this.subscriberLinkState.paywall_success_path;
      }
      if (this.$route.query.redirect) {
        return this.$route.query.redirect;
      }
      const route = this.$store.getters.userIsOnboardedState
        ? {name: 'search-all-records', query: {tab: TAB_ID_INDEXED_RECORDS}}
        : {name: 'familytree-onboarding-start'};
      return this.$router.resolve(route).href;
    },
    showCancel() {
      return (
        this.currentSubscription &&
        this.currentSubscription.plan.billing_period === this.pricing &&
        !this.currentSubscription.cancel_at_period_end
      );
    },
    freeYearPromo() {
      return (
        this.featureSwitchesState.XMAS_DISCOUNT_SUBSCRIPTION || this.featureSwitchesState.CNY_DISCOUNT_SUBSCRIPTION
      );
    },
    showFreePlanCard() {
      return !this.redirectedFromRecord || this.userIsLoggedInState;
    },
    yearly50PercentOff() {
      return this.isPricingYearly && !this.userIsSubscribedState && this.featureSwitchesState.MIDAUTUMN_SALE;
    },
  },
  methods: {
    setPricing(type) {
      this.pricing = type;
    },
    setPricingMonthly() {
      this.setPricing(PRICING_MONTHLY);
    },
    setPricingYearly() {
      this.setPricing(PRICING_YEARLY);
    },
    startSubscription(planId) {
      let cleanQuery = omit(this.$route.query, ['autostart_plan']);
      const cancelUrl = this.$router.resolve({name: this.$route.name, query: cleanQuery}).href;
      this.stripeLoading = true;
      this.$store
        .dispatch('createSubscriptionCheckoutSessionAction', {
          plan_id: planId,
          success_url: this.successUrl,
          cancel_url: cancelUrl,
        })
        .then(res => {
          if (res.session_id) {
            this.$stripe.redirectToCheckout({sessionId: res.session_id});
          }
        })
        .catch(error => {
          this.stripeLoading = false;
          if (error && error.response && error.response.data) {
            this.$toasted.error(error && error.response && error.response.data);
          }
        });
    },
    seeAllPlansClick() {
      this.$router.push({name: 'subscription-plans'});
      this.$scrollTo({x: 0, y: 0});
    },
    onVideoClick() {
      AnalyticsAmplitudeHandler.trackPlayVideoEvent('How My China Roots Works', getRoutePageName(this.$route));
    },
  },
  name: 'SubscriptionPlansPage',
  components: {
    McrButton,
    SubscriptionReactivateButton,
    RecordsPaywallPage,
    XmasBannerSubscriptionPlans,
    CnyBannerSubscriptionPlans,
    EndActions,
    McrButtonRouterLink,
    McrWikiGlossaryLink,
    SubscriptionCancelButton,
    StartSubscriptionButton,
    StandOutBlockList,
    StandOutBlock,
    LogoWall,
    LazyYoutubeVideo,
  },
};
</script>

<style lang="scss" scoped>
.subscription-plans-page {
  .limited_content {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
  &.loading-state {
    padding-bottom: 50px;
  }

  .heading {
    text-align: center;
    margin: 0 auto;

    .title {
      margin: 0;
    }

    .sub-title {
      margin: 16px auto 0;
      color: $neutral-500;
      font-weight: 400;
    }
  }

  .mcr-loading-indicator {
    margin: 14px 0;
  }

  .stand-out-block-list {
    margin-top: 10px;
    .stand-out-block {
      padding: 24px 32px 24px;
      height: fit-content;
      max-width: 315px;
      border-top: 8px solid $neutral-400;

      .price-explanation {
        color: $neutral-500;
      }

      &.standard-plan {
        border-top: 8px solid $primary-400;
      }
    }
    @media only screen and (max-width: $breakpoint-mobile) {
      margin-top: 2px;
    }
  }

  .plan-content {
    display: flex;
    flex-direction: column;
    width: 100%;

    .plan-name {
      color: $neutral-600;
      margin-bottom: 32px;
      font-size: 20px;
      line-height: 28px;
      font-weight: bold;
    }

    .yearly-discount {
      position: absolute;
      right: 24px;
      top: 24px;
    }

    .price {
      font-size: 48px;
      margin-bottom: 32px;
      height: 34px;
      white-space: nowrap;
      letter-spacing: -0.02em;

      .period {
        font-size: 24px;
      }
    }
    .custom-price {
      font-size: 36px;
      line-height: 34px;
      height: 78px;
      margin-top: 10px;
    }

    hr {
      border-color: $neutral-200;
      margin: 16px 0 0;
    }

    .bill-description {
      color: $neutral-600;

      .discounted {
        text-decoration: line-through;
      }
    }

    .plan-description {
      width: 100%;
      font-size: 18px;
      line-height: 28px;

      .item {
        margin-top: 20px;
        .mcr-glossary-link {
          display: inline;
          color: $text-color;

          &.is-bold {
            font-weight: 600;
          }
          &::v-deep span {
            vertical-align: middle;
          }
        }

        .icon {
          font-size: 18px;
          width: 20px;
          height: 24px;
          padding: 0 5px 0 0;
        }

        &.highlight {
          color: $text-color;
          line-height: $line-height;
        }

        &.checked::before {
          content: '✓';
          display: inline-block;
          margin-right: 5px;
        }
      }
    }

    .disclaimer {
      text-align: center;
      font-size: 14px;
      color: $neutral-500;
      margin-top: 8px;
      & + .disclaimer {
        margin-top: 0px;
      }
    }
    .invisible {
      color: transparent;
    }
    .cancel-plan {
      text-align: center;
      margin-top: 12px;
    }

    .current-plan {
      background: $neutral-700;
      color: white;
      margin-top: 24px;
      font-weight: 700;
      padding: 4px 8px;
      border-radius: 4px;
      align-self: center;
    }
    .invisible + .current-plan {
      margin-top: 0;
    }

    .button {
      width: 100%;
      margin-top: 32px;
    }
    .subscription-reactivate-button {
      margin-top: 32px;
    }

    @media only screen and (max-width: $breakpoint-mobile) {
      .custom-price {
        font-size: 32px;
        line-height: 40px;
      }
    }
  }

  .is-switch {
    display: flex;
    justify-content: center;
    border-radius: 4px;
    $tab-color: $mcr-dark-grey;
    margin: 64px 0 0;

    .tab {
      border: 1px solid $tab-color;
      color: $tab-color;
      margin-right: 0;
      border-radius: 0;
      text-transform: uppercase;
      flex-grow: 0;
      text-align: center;
      align-items: center;
      justify-content: center;
      display: flex;
      cursor: pointer;
      font-size: 14px;
      width: 154px;
      height: 27px;
      font-weight: 800;

      &:first-of-type {
        border-radius: 4px 0 0 4px;
      }
      &:last-of-type {
        border-radius: 0 4px 4px 0;
      }
      &.is-active {
        background-color: $tab-color;
        color: $off-white;
      }
    }
  }

  .logo_wall {
    margin: 30px 0;
  }

  .video-block {
    .title {
      margin-bottom: 16px;
      text-align: center;
    }
    @media only screen and (min-width: $breakpoint-tablet) {
      width: 100%;
    }
  }

  .information {
    margin-top: 50px;

    .help-text {
      color: $mcr-light-tan;
      font-weight: bold;
    }

    .readable_content.welcome {
      text-align: center;
      padding-top: 0;
      padding-bottom: 0;
      margin-bottom: 100px;

      .heading-3,
      p {
        margin-left: auto;
        margin-right: auto;
        max-width: 697px;
      }
      p {
        margin: 20px auto 44px;
      }
    }

    .image_panel + .image_panel {
      margin-top: 44px;
    }

    .image_panel {
      .gif {
        border-radius: 4px;
      }
    }
  }

  .basic-plan-overview {
    margin: 40px auto 0;
    max-width: 534px;

    .heading-6 {
      text-align: center;
    }

    .benefits-list {
      display: flex;
      column-gap: 40px;
      row-gap: 20px;
      margin-top: 24px;
      .column {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        .mcr-glossary-link {
          display: inline;
          color: $text-color;
          &::v-deep span {
            vertical-align: middle;
          }
        }
      }
    }

    .see-all-plans {
      text-align: center;
      margin-top: 20px;
      font-weight: 500;
    }

    @media only screen and (max-width: $breakpoint-mobile) {
      .benefits-list {
        flex-direction: column;
        padding: 0 16px;
      }
      .heading-6 {
        padding: 0 16px;
      }
    }
  }

  .login-link-cta {
    text-align: center;
    margin-top: 32px;
    color: $neutral-600;
  }
  .basic-plan-overview + .login-link-cta {
    margin-top: 16px;
  }

  .sub-information {
    margin: 86px auto 0;
    max-width: $content-max-width;

    .heading-4 {
      text-align: center;
      margin: 48px 0 48px;
    }

    div {
      margin-bottom: 48px;
    }
  }
  .design-by {
    max-width: 622px;
    margin: 120px auto 80px;
    text-align: center;

    .heading {
      display: flex;
    }
  }

  .privileges {
    max-width: $content-max-width;
    margin: 86px auto 108px;
    .heading-3 {
      max-width: 523px;
      text-align: center;
      margin: 0 auto 48px;
    }
    .list {
      display: flex;
      column-gap: 32px;
      > * {
        width: 100%;
        max-width: 378px;
        padding: 24px;
      }
      .emoji {
        font-size: 36px;
        line-height: 44px;
      }
      .item-title {
        font-weight: 800;
        margin-top: 12px;
        font-size: 20px;
        line-height: 28px;
      }
    }
  }

  .image_panel {
    align-items: center;
    .text-block {
      margin: 20px 0 0;
      p {
        margin: 0;
      }
      p + p {
        margin-top: 26px;
      }
    }

    .image_panel_image {
      display: flex;
    }

    + .image_panel {
      margin-top: 36px;
    }
  }

  p {
    color: $neutral-500;
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    .limited_content {
      padding-top: 44px;
    }
    .heading {
      margin-left: 16px;
      margin-right: 16px;
      .title {
        font-size: 32px;
        line-height: 40px;
      }
    }

    .image_panel + .image_panel {
      margin-top: 72px;
    }

    .information {
      margin-top: 32px;
      .readable_content.welcome {
        margin-bottom: 76px;

        .heading-3,
        p {
          text-align: left;
        }
        p {
          margin: 20px auto 40px;
        }
      }

      .image_panel + .image_panel {
        margin-top: 64px;
      }
    }
    .sub-information {
      margin: 56px 0 56px;
      padding: 0 16px;
      .heading-4 {
        max-width: 287px;
        margin: 24px auto 40px;
      }
      .logo_wall {
        margin-bottom: 24px;
      }
    }
    .design-by {
      padding: 0 16px;
      margin-bottom: 72px;
    }
    .image_panel {
      padding: 0 16px;
      .image_panel_text {
        margin-bottom: 40px;
      }
      .image_panel_image {
        margin-bottom: 0;
      }
    }
    .privileges {
      margin: 64px auto 64px;
      .heading-3 {
        margin-bottom: 24px;
      }
      .list {
        flex-direction: column;
        > * {
          width: unset;
          padding: 16px;
        }
        p {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
